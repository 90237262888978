@font-face {
  font-family: "ClashGrotesk";
  src: url("./assets/fonts/ClashGrotesk-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  background: #151517 !important;
  overflow: hidden !important;
  font-family: ClashGrotesk, sans-serif !important;
}

.tw-connected-wallet__address {
  color: black !important;
}

.tw-connected-wallet__balance {
  color: #49484b !important;
}

.background-text {
  position: fixed;
  top: 30%;
  left: 30%;
  width: 100%;
  height: 100%;
  z-index: -1;

  opacity: 0.2;
  color: #ffec6eb2;
  font-size: 250px;
  letter-spacing: 10px;

  background-color: transparent;
  background-size: cover;
  background-attachment: fixed;
  pointer-events: none;

  overflow: hidden;
}

.background-text::after {
  content: attr(data-text);

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

@media (max-width: 768px) {
  .background-text {
    top: 50%;
    left: 0;
    font-size: 110px;
    letter-spacing: 1px;
  }
}

.bg-img {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#151517b6, #151517d0),
    url("./assets/logo.png");
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  backdrop-filter: blur(1px);
}

.content {
  position: relative;
  overflow-y: auto;
  height: 90vh;
  padding-bottom: 10px;
  background: #15151745;
  backdrop-filter: blur(3px);
}

::-webkit-scrollbar-track {
  background-color: #212121 !important;
  /* Change to your desired color */
}

/* Change the color of the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: rgba(233, 217, 70, 0.873) !important;
  border-radius: 10px !important;
}

/* Optionally, you can also style other properties like width and height */
::-webkit-scrollbar {
  width: 10px;
  border-radius: 20px;
  height: 8px;
}

@media (max-width: 720px) {
  .bg-img {
    background-size: 100%;
  }
}
